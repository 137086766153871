import React from 'react';
import './CompanyInformation.css';
import nihayaKeyLogo from '../../assets/NIHAYAKEYLOGO.png';

const CompanyInformation = () => {
  return (
    <div className="company-info-page">
      <div className="company-info-header">
        <a href="/" className="back-button">← Back</a>
        <img src={nihayaKeyLogo} alt="NIHAYA" className="company-logo" />
      </div>
      <div className="company-info-content">
        <h1>Company Information</h1>
        <div className="info-section">
          <div className="info-item">
            <h2>Company Name</h2>
            <p>Wally Damn</p>
          </div>
          <div className="info-item">
            <h2>Chamber of Commerce</h2>
            <p>70961581</p>
          </div>
          <div className="info-item">
            <h2>VAT Number</h2>
            <p>NL237418873B01</p>
          </div>
          <div className="info-item">
            <h2>Address</h2>
            <p>Hofwijckstraat 147</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation; 