import React from 'react';
import './PrivacyPolicy.css';
import nihayaKeyLogo from '../../assets/NIHAYAKEYLOGO.png';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-header">
        <a href="/" className="back-button">← Back</a>
        <img src={nihayaKeyLogo} alt="NIHAYA" className="company-logo" />
      </div>
      <div className="privacy-content">
        <h1>Privacy Policy</h1>
        <p className="effective-date">Effective Date: 10-12-2024</p>

        <div className="policy-section">
          <p className="intro">
            NIHAYA ("we," "us," or "our") values the privacy of our customers and website visitors. 
            This privacy policy explains how we handle the personal data we collect when you sign up for our newsletter.
          </p>

          <section>
            <h2>1. What Data Do We Collect?</h2>
            <p>When you sign up for our newsletter, we collect the following data:</p>
            <ul>
              <li>Your email address.</li>
            </ul>
          </section>

          <section>
            <h2>2. Why Do We Collect This Data?</h2>
            <p>We use your email address exclusively for the following purposes:</p>
            <ul>
              <li>Sending our newsletter with updates about our products, promotions, and other relevant information.</li>
            </ul>
            <p>We will never use or share your email address for other purposes without your consent, unless it is necessary to provide our services (e.g., through an email service provider like Mailchimp).</p>
          </section>

          <section>
            <h2>3. How Long Do We Keep Your Data?</h2>
            <p>We store your email address as long as you are subscribed to our newsletter. You can unsubscribe at any time, after which we will delete your data from our systems within 30 days.</p>
          </section>

          <section>
            <h2>4. How Do We Protect Your Data?</h2>
            <p>We implement appropriate technical and organizational measures to protect your data from unauthorized access, loss, or misuse. These include:</p>
            <ul>
              <li>Encryption of data during transmission (SSL certificate).</li>
              <li>Secure storage of data.</li>
            </ul>
          </section>

          <section>
            <h2>5. Your Rights</h2>
            <p>You have the right to:</p>
            <ul>
              <li>Access the data we have collected about you.</li>
              <li>Request correction or deletion of your data.</li>
              <li>Object to the use of your data for marketing purposes.</li>
              <li>Withdraw your consent. You can do this by unsubscribing via the link at the bottom of our newsletter or by contacting us.</li>
            </ul>
            <p>To exercise these rights, please email us at <a href="mailto:info@nihaya.eu">info@nihaya.eu</a></p>
          </section>

          <section>
            <h2>6. Cookies and Tracking</h2>
            <p>We do not use tracking technologies in our newsletters. If this changes in the future, we will inform you and request your consent.</p>
          </section>

          <section>
            <h2>7. Sharing Your Data with Third Parties</h2>
            <p>We do not share your data with third parties except with service providers who assist us in sending newsletters (e.g., Mailchimp or similar email services). These parties process your data solely according to our instructions and in compliance with applicable privacy laws.</p>
          </section>

          <section>
            <h2>8. Contact Information</h2>
            <p>If you have any questions about this privacy policy or how we handle your data, please contact us: <a href="mailto:info@nihaya.eu">info@nihaya.eu</a></p>
          </section>

          <section>
            <h2>9. Changes to Our Privacy Policy</h2>
            <p>We reserve the right to update this privacy policy. Any changes will be published on this page. Please check this page regularly to stay informed about updates.</p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy; 